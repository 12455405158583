import SesamyFullLogoIcon from "@/components/ui/icons/SesamyFullLogoIcon";

interface HeaderProps {
  logoSrc?: string;
}

const Header = ({ logoSrc }: HeaderProps) => {
  return (
    <header
      data-testid="header"
      className="flex h-14 flex-shrink-0 items-center justify-center border-b border-gray-200 bg-white p-4"
    >
      {logoSrc ? (
        <img alt="logo" src={logoSrc} className="h-5 w-auto" />
      ) : (
        <SesamyFullLogoIcon
          className="h-5 w-auto"
          data-testid="sesamy-full-logo-icon"
        />
      )}
    </header>
  );
};

export default Header;
