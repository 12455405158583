"use client";

import Header from "@/components/layout/Header/Header";
import ErrorMessage from "@/components/setup/ErrorMessage/ErrorMessage";
import { useEffect } from "react";

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    // Log the error to datadog
    console.error(error);
  }, [error]);

  return (
    <div className="flex h-screen flex-col">
      <Header />
      <main className="flex-1 px-4">
        <ErrorMessage noLang error={"errors.internal_server"} />
      </main>
    </div>
  );
}
